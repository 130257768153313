import axios from 'axios'
import store from '@/store/index'
import { getInstance } from '@pantheon-systems/auth0-vue-module'

export default {
  async getKeys() {
    const token = await getInstance().getTokenSilently()
    try {
      const resp = await axios.post('/_api/keys', {
        apiKey: store.state.apiKey,
        method: 'get'
      }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
      })
      return resp.data
    }
    catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  },

  async getKeysPaged(pagedUrl) {
    const token = await getInstance().getTokenSilently()
    try {
      const resp = await axios.post('/_api/keys-paged', {
        method: 'get',
        apiKey: store.state.apiKey,
        pagedUrl
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resp.data
    } catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  },

  async addKey(pkeydata, name) {
    const token = await getInstance().getTokenSilently()
    const payload = {
      data: {
        type: 'tls_private_key',
        attributes: {
          key: pkeydata,
          name: name
        }
      }
    }

    try {
      const resp = await axios.post('/_api/keys', {
        apiKey: store.state.apiKey,
        method: 'post',
        payload
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resp.data;
    }
    catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  },

  async deleteKey(id) {
    const token = await getInstance().getTokenSilently()
    try {
      const resp = await axios.post('/_api/keys', {
        apiKey: store.state.apiKey,
        method: 'delete',
        payload: id
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resp.data.status
    }
    catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  }
}
