<template>
  <div id="private-keys">
    <LoadingSpinner :showHide="showHide" />

    <PageHeader heading="Private Keys" />

    <b-row class="mt-2">
      <b-col>
        <CustomCertDns />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="6">
        <b-input-group size="sm" prepend="Search by cert name...">
          <b-form-input v-model="search"></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row v-if="e" class="mt-2">
      <b-col>
        <ErrorMessage>{{ e }}</ErrorMessage>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <b-table striped hover bordered :items="tableValues">
          <template v-slot:cell(actions)="row">
            <b-button  size="sm" variant="danger" @click="deleteAction(row.item.ID)">Delete</b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-b-visible="visibleHandler" v-if="nextPageUrl !== null">
      <b-col>
        <p>Something to trigger v-b-visible</p>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import PrivateKeyApi from '@/services/api/PrivateKeys'
import ErrorMessage from '@/components/ErrorMessage.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import CustomCertDns from '@/components/CustomCertDns.vue'

export default {
  name: 'private-keys',
  components: {
    PageHeader,
    ErrorMessage,
    LoadingSpinner,
    CustomCertDns,
  },
  data() {
    return {
      privateKeys: [],
      loading: true,
      e: null,
      search: '',
      nextPageUrl: null,
    }
  },
  computed: {
    filteredKeys: function() {
      let self = this
      if (self.search) {
        return self.privateKeys.filter(k => k.attributes.name.indexOf(self.search) >= 0)
      } else {
        return this.privateKeys
      }
    },
    showHide: function() {
      if (this.loading) {
        return 'show'
      } else {
        return 'hide'
      }
    },
    tableValues: function() {
      return this.filteredKeys.map(x => ({
        ID: x.id,
        Name: x.attributes.name,
        Actions: ''
      }))
    }
  },
  async mounted() {
    try {
      const keyResponse = await PrivateKeyApi.getKeys()
      this.privateKeys = keyResponse.data
      this.nextPageUrl = keyResponse.nextPage
    }
    catch (e) {
      this.e = e
    }
    this.loading = false
  },
  methods: {
    deleteAction: async function(id) {
      // Show the confirmation modal.
      const answer = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the key with ID ${id}? This action cannot be undone!`, {
        title: 'Confirm Delete',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true
      })

      if (answer == true) {
        const resp = await PrivateKeyApi.deleteKey(id)
        if (resp == 204) {
          this.privateKeys = this.privateKeys.filter(k => k.id != id)
          if (this.filteredKeys.length == 0 && this.search != '') {
            this.search = ''
          }
        }
      }
    },
    visibleHandler: async function(s) {
      if (s === true) {
        if (this.nextPageUrl !== null) {
          this.loading = true
          const resp = await PrivateKeyApi.getKeysPaged(this.nextPageUrl)
          this.loading = false
          if (! resp.error) {
            this.privateKeys = [
              ...this.privateKeys,
              ...resp.data
            ]
            this.nextPageUrl = resp.nextPage
          }
        }
      }
    }
  }
}
</script>
